import React, { useContext, useEffect } from "react";

import { CurrentUserContext } from "../../providers/auth";

import Layout from "../../components/layout";
import Loading from "../../components/loading";

const LoginPage = () => (
    <Layout checkAuth={false} seoTitle="Loading...">
        <Login />
    </Layout>
);

export default LoginPage;

const Login = () => {
    const currentUser = useContext(CurrentUserContext);

    useEffect(() => {
        if (currentUser) {
            currentUser.signIn();
        }
    }, [currentUser]);

    return <Loading />;
};
